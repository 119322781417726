import React, { useEffect } from 'react';
// import { MDXRenderer } from "gatsby-plugin-mdx";
import Layout from '../components/Layout';
import { graphql, navigate } from 'gatsby';
export { default as Head } from '../components/Head';

// import { SEO } from "../components/seo"
const getRedirectLanguage = () => {
  if (typeof navigator === `undefined`) {
    return 'de';
  }
  const lang =
    navigator && navigator.language && navigator.language.split('-')[0];
  if (!lang) return 'de';

  // switch (lang) {
  //   case "ru":
  //     return "ru";
  //   case "ro":
  //       return "ro";
  //   // case "en":
  //   //   return "en";
  //   default:
  //     return "ro";
  // }
  return lang;
};

const introtext = {
  de: 'Startseite Festival of Hope',
  en: "Welcome to Essen's Festival of Hope",
};

function Page({ pageContext }) {
  const page = pageContext;
  let urlLang = getRedirectLanguage();
  useEffect(() => {
    if (['en', 'de'].includes(urlLang)) {
      navigate(`/${urlLang}/`, { replace: true });
    } else {
      navigate(`/de/`, { replace: true });
    }
  }, []);
  return (
    <Layout>
      <div>
        <div className="home-content p-6">
          <div className="columns is-centered">
            <h2>{introtext[urlLang]}</h2>
          </div>
          <div className="columns is-centered">
            <a href="/en/" className="button">
              English
            </a>
            <a href="/de" className="button">
              Deutsch
            </a>
          </div>
        </div>
      </div>
    </Layout>
  );
}

export default Page;
